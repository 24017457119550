
import { defineComponent } from "vue";

export default defineComponent({
  name: "show-quote-ref-no",
  props: {
    invoice: Object,
  },
  setup() {

    return {
    };
  },
});
