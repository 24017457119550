
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'


export default defineComponent({
    props: {
        isError: {
            type: Boolean,
            required: true,
            default: false
        }
    },

   setup() {
       const store = useStore();

       const openModal = computed(() => {
           return store.getters.getModal
       })

       const openModalValue = computed(() => {
           return store.getters.getModalValue
       })

       const openModalLoading = computed(() => {
           return store.getters.getModalLoading
       })

       const inst = getCurrentInstance()
       const toFirst = () => {
        setTimeout(() => {
            const policyNoRef:any = inst?.refs?.policyNoRef
        policyNoRef.focus()
        }, 500);
       }

       return {
        toFirst,
           openModal,
           openModalValue,
           openModalLoading
       }
   }

})
